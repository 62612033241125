import PropTypes from 'prop-types'
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import gatsbylogo from '../images/gatsbyicon.png'
import reactlogo from '../images/reactlogo.png'


// export default function Image() {

export default function  Header (props) {

  const data = useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "reactlogo.png" }) {
          childImageSharp {
            # Specify a fixed image and fragment.
            # The default width is 400 pixels
            fluid(maxWidth: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      `)
      return (
        <header id="header" style={props.timeout ? { display: 'none' } : {}}>
          <div className="logo">
            <span className="icon fa-code"></span>
          </div>
          <div className="content">
          {/* <Img
                  fluid={ data.file.childImageSharp.fluid } alt="AnyBodyCanCode.in"
                /> */}
            <div className="inner">
              <h1>anyonecancode.in</h1>
              <p>
                A drive for training the non-tech youths to become coders by {' '}
                <a href="http://makeanybot.com">SMK Solutions</a> and launching the program
                <br />
                for free and voluntarily providing all the IT infrastructure {' '}
                such as office space and laptops. Free teaching from very well experienced software engineers.
				<h3>2022: Working with Product Development Company (AI ML BOTS) in Bangalore on Engineering Services Model</h3>
				<h3>2022: Working with Product Development Company (DOCUMENT PROCESSING AUTOMATION) in Bangalore on Engineering Services Model</h3>	
                <h3>2021: Working with Product Development Company (Client One) in Bangalore on Engineering Services Model</h3>
                <h3>2021: Working with Product Development Company (Client Two) in Bangalore on Engineering Services Model</h3>			
				<h3>2021: Working with Hospitality Service and Product Company (Tripthril) in Karnataka on Engineering Services Model</h3>				
                <h3>2021/Jan/20: CMS, Strapi, React, Bootstrap - 9 Web Projects have been handled by the team</h3>          
                <Img
                  fluid={ reactlogo }
                  alt="AnyBodyCanCode.in"
                />

                <h3>2021/Jan/29: Tank Refilling Automation - 3 Project is been implemented by the team</h3>
                <h3>2021/Jan/03: Gatsby, React, Bootstrap - 6 Web Projects have been handled by the team</h3>

                <h3>2021/Jan/15: Showroom Light Automation - in house built and is being implemented by the team</h3>
                <h3>2021/Jan/20: Gatsby, React, Bootstrap - 9 Web Projects have been handled by the team</h3>          
                <Img
                  fluid={ reactlogo }
                  alt="AnyBodyCanCode.in"
                />

                <h3>2021/Jan/29: Tank Refilling Automation - 3 Project is been implemented by the team</h3>
              </p>
            </div>
          </div>
          <nav>
            <ul>
              <li>
                <button
                  onClick={() => {
                    props.onOpenArticle('intro')
                  }}
                >
                  Intro
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    props.onOpenArticle('its-proven')
                  }}
                >
                  Proven
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    props.onOpenArticle('about')
                  }}
                >
                  About
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    props.onOpenArticle('works')
                  }}
                >
                  Works
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    props.onOpenArticle('contact')
                  }}
                >
                  Contact
                </button>
              </li>
              <li>
                <button>
                  <a href="http://makeanybot.com/anybodycancode" target="_blank">
                    Blog
                    </a>
                </button>
              </li>
            </ul>
          </nav>
        </header>
      )
    }

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

// export default Header
