import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'
import anyonecancodeone from "../videos/AnyOneCanCodebySMKWeb.mp4"
import anyonecancodetwo from "../videos/AnyOneCanCodebySMKWebTwo.mp4"
import anyonecancodethree from "../videos/AnyOneCanCodebySMKWebThree.mp4"

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            It is said that any profession you want get into needs one to study
            academic courses, degrees and obtain a certificate. For example
            a doctor to carry out a surgery should have gone to medical college
            and obtained a MBBS, MS degrees. Similarly if someone has to practice
            law and fight a case in law of court, should have a degree in law
            and obtained a certificate to fight a case in court of law.
          </p>
          <p>
            <video controls style={{ width: `100%`, alignItems: 'center' }} >
              <source src={anyonecancodeone} type="video/mp4" />
            </video>
          </p>

          <p>
            But to learn computer programming and write code does not require any
            degree or certificate. You can become a software programmer or engineer
            just by learning coding.

            It requires curiosity and a goal to achieve, yes it also requires teachers
            we are here for the same.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'its-proven' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">It's Proven</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            I know several Software engineers who have not completed their PUC,
            and many have not completed their graduation. But they are successful
            software engineers.
          </p>

          <p>
            Let us hear from one who has mastered in Technology and
            Entrepreneur Mr. Srihari Boregowda
          </p>

          <p>
            <video controls style={{ width: `100%`, alignItems: 'center' }}>
              <source src={anyonecancodethree} type="video/mp4" />
            </video>
          </p>

          {/* <p>
            <video controls style={{ width: `100%`, alignItems: 'center' }}>
              <source src={anyonecancodetwo} type="video/mp4" />
            </video>
          </p> */}

          <p>
            There are several software engineers who have obtained non technical
            degrees like BCom, BA, etc. They are also working in a very good company
            as software engineers and have solved several problems by creating the software
            solutions.
          </p>

          <p>
            I am one of the person who didn't study computer engineering or any
            engineering. I am BSc graduate and till completion of this degree I
            had never touched or seen a computer very closely. But I became a
            software programmer in 6 monts. It all requires is a good Guru and a
            committed institution.
          </p>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            anyonecancode.in and anybodycancode.in are the brands of SMK Solutions. This is the theme under which we are
            training the jobless youth to learn coding and develop softwares.
          </p>

          <p>
            SMK Solutions is registered as Proprietorship entity. It provides software development services
            and consultancy to build software products. Shiddu Mageppa heads the company and guided and mentored by
            many friends and industry experts.
          </p>

          <p>
            SMK has a residential office in Bangalore for marketing, and sales purpose. And also works on software development
            consultancy services.

            And SMK has also setup a facility for training beginners and guiding who have stuck in the middle. This work is being
            done under brand anyonecancode.in. The objective is to create software programmers and create opportunity to local
            software programmers.
          </p>

          <p>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:6727497116903600128" height="669" width="504" frameBorder="0" allowFullScreen="" title="Embedded post"></iframe>
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'works' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Trainees transformed into Web Developers have been really putting efforts to learn the coding and deliverying best performing SPA, Website, and Enterprise Applications.
          </p>
            <p>
              Following is brief list of projects delivered out of 25 odd projects by the trainees turned into full time Web Developers. 
            </p>

            <ol>
              <li>
              <a href="http://webuildd.com" target="_blank">
                Developed using ReactJS, CSS, Global CSS, Javascipt. Content Loaded Dynamically, Next Generation Image Formats, Helmet for SEO, and Complex Routing is implemented.
              </a>
              </li>
              <li>
              <a href="http://phasepowerproducts.com" target="_blank">
                Developed using ReactJS, CSS, Global CSS, Javascipt. Content Loaded Dynamically, Next Generation Image Formats, Helmet for SEO, and Complex Routing is implemented.
              </a>
              </li>
              <li>
              <a href="https://toolmecin.netlify.app" target="_blank">
                Developed using ReactJS, CSS, Global CSS, Javascipt. Content Loaded Dynamically, Next Generation Image Formats, Helmet for SEO, and Complex Routing is implemented.
              </a>
              </li>
              <li>
              <a href="http://surabhiinc.co" target="_blank">
                Developed using Bootstrap, CSS, Javascipt.
              </a>
              </li>
              <li>
              <a href="http://surabhisteel.com" target="_blank">
                Developed using ReactJS, CSS, Global CSS, Javascipt. Content Loaded Dynamically, Next Generation Image Formats, Helmet for SEO, and Complex Routing is implemented. Website for a construction and engineering company.
              </a>
              </li>
              <li>
              <a href="https://www.jsfindia.in/" target="_blank">
                Developed using ReactJS, CSS, Global CSS, Javascipt. Content Loaded Dynamically, Next Generation Image Formats, Helmet for SEO, and Complex Routing is implemented.
              </a>
              </li>
              <li>
              <a href="http://dentalaarogya.com/" target="_blank">
                Developed using Gatsby. 
              </a>
              </li>
              <li>
                Many customer projects.
              </li>
            </ol>
          <p>
            Coding works done by Trainees is listed below:
            </p>

            <ol>
              <li>
              <a href="http://anyonecancode.in/bgc/countries.html" target="_blank">
                Web Pages by BNT Coders in 12 days of learning
              </a>
              </li>
              <li>
              <a href="http://anyonecancode.in/dsr/countriesarticles.html" target="_blank">
                Web Pages by Banahatti Coders in 12 days of learning
              </a>
              </li>
              <li>
              <a href="http://anyonecancode.in/bcb/cover.html" target="_blank">
                A Small Web Portal using Bootstrap templates in 20 days of learning
              </a>
              </li>
              <li>
              <a href="https://studio.code.org/projects/spritelab/W2XRfszhI_S3orQOdfhFVk8hNneylXCzNBD1i6X3Fy0" target="_blank">
                Game by High School Kid in 12 days of learning
              </a>
              </li>
              <li>
                More to come soon
              </li>
            </ol>
          <p>
              SMK Solutions is been running the classes under the brand anyonecancode from 09:00 AM till 06:30 PM.
          </p>
          <p>
              SMK Solutions has become a preferred partner for few of the software companies in Silicon valley of India. 
            </p>
            <ol>
              <li>
                09:00 AM to 01:00 PM.
              </li>
              <li>
                02:00 PM to 06:30 PM.
              </li>
            </ol>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p>
              Shiddu Mageppa |
              +91 97400 16279 |
              <a href="mailto:shiddu@gmail.com">shiddu@gmail.com</a> |
              <a href="http://makeanybot.com">makeanybot.com</a>
          </p>
          <form method="post" action="https://formspree.io/shiddu@gmail.com">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/shiddu"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/in/shiddumageppa"
              className="icon fa-linkedin"
              >
                <span className="label">Linkedin</span>
              </a>
            </li>
            {/* <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li> */}
            <li>
              <a
                href="https://github.com/shiddugmail"
                className="icon fa-github"
              >
                <span className="label">Github</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
        <article
          id="blog"
          className={`${this.props.article === 'blog' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Blog</h2>
          <p>
              Visit this <a href="http://makeanybot.com/anybodycancode" target="_blank"> here </a> for my blogs.
          </p>
          {close}
        </article>
      
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
